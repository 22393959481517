import {
    FetchHeaders
} from "../../helpers/fetch-headers";
// @ts-ignore
import * as Pristine from 'pristinejs';
// @ts-ignore
declare var grecaptcha;
// @ts-ignore
declare var dataLayer;

export class ContactForm {

    trigger: HTMLButtonElement;
    static pristine: any;
    static pristineConfig = {
        classTo: 'field',
        errorClass: 'error',
        successClass: 'success',
        errorTextParent: 'field',
        errorTextTag: 'p',
        errorTextClass: 'error'
    };
    contactForm: HTMLFormElement;
    contactFormCta: HTMLButtonElement;
    resultMessage: HTMLElement;
    fields: NodeListOf < HTMLElement > ;
    timer: HTMLElement;

    constructor() {

        this.contactForm = < HTMLFormElement > document.getElementById('contact-form');
        this.contactFormCta = this.contactForm.querySelector('button[type="submit"]');
        this.resultMessage = document.querySelector('.result-message');
        this.fields = this.contactForm.querySelectorAll('input,select,textarea');

        ContactForm.pristine = new Pristine(this.contactForm, ContactForm.pristineConfig);

        const contactType = document.querySelectorAll('[name=contact_type]');
        
        const company_name = document.getElementById('company_name');
        const company_name_parent = company_name.closest('.field');

        contactType.forEach((el: HTMLInputElement) => {
            el.addEventListener('change', (ev: Event) => {
                const value = (< HTMLInputElement > ev.target).value;
                if (
                    value == 'company'
                ) {
                    company_name.setAttribute('data-pristine-required-message', 'Inserisci la ragione sociale');
                    company_name.removeAttribute('disabled');
                    company_name.setAttribute('required', 'required');
                    company_name_parent.classList.remove('disabled');
                } else {
                    company_name.setAttribute('disabled', 'disabled');
                    company_name.removeAttribute('required');
                    company_name.removeAttribute('data-pristine-required-message');
                    company_name_parent.classList.add('disabled');
                }
                ContactForm.pristine.destroy();
                ContactForm.pristine = new Pristine(this.contactForm, ContactForm.pristineConfig);
            });
        });

        this.contactFormCta.addEventListener('click', (ev: MouseEvent) => {
            ev.preventDefault();

            const valid = ContactForm.pristine.validate();

            if (valid) {

                this.contactFormCta.disabled = true;
                this.contactFormCta.classList.add('loading');

                let resumeFields = document.querySelectorAll('.resume-field');

                resumeFields.forEach(element => {
                    element.innerHTML = '';
                });

                const data: any = {};

                this.fields
                    .forEach((el: HTMLInputElement | HTMLSelectElement) => {
                        if (el.type == 'radio' || el.type == 'checkbox') {
                            // @ts-ignore
                            if (el.checked) {
                                data[el.getAttribute('name')] = el.value;
                            }
                        } else {
                            data[el.getAttribute('name')] = el.value;
                        }
                    });


                const siteKey = this.contactFormCta.dataset.sitekey;
                const action = this.contactForm.getAttribute('action');

                grecaptcha.ready(() => {
                    grecaptcha
                        .execute(siteKey, {
                            action: 'submit'
                        })
                        .then((token: string) => {
                            console.log(token);
                            ContactForm.performAsyncRequest(
                                action,
                                data,
                                (data: any) => {

                                    this.contactFormCta.disabled = false;
                                    this.contactFormCta.classList.remove('loading');

                                    if (data.success) {

                                        this.resultMessage.classList.add('visible');

                                        this.contactForm.reset();
                                        document.querySelector('.result-message__success').classList.add('visible');
                                        document.querySelector('.result-message__error').classList.remove('visible');

                                        if (data.result && data.request) {
                                            document.getElementById('resume-letame').innerHTML = data.request.letame;
                                            document.getElementById('resume-liquame').innerHTML = data.request.liquame;
                                            document.getElementById('resume-pollina').innerHTML = data.request.pollina;
                                            document.getElementById('resume-mais').innerHTML = data.request.mais;
                                            document.getElementById('resume-triticale').innerHTML = data.request.triticale;
                                            document.getElementById('resume-sorgo').innerHTML = data.request.sorgo;
                                            document.getElementById('resume-sansa').innerHTML = data.request.sansa;
                                            document.getElementById('resume-altra').innerHTML = data.request.altra;

                                            document.getElementById('resume-chp').innerHTML = data.request.chp;
                                            document.getElementById('resume-iniezione').innerHTML = data.request.iniezione;
                                            document.getElementById('resume-distanza').innerHTML = data.request.distanza;

                                            data.result.forEach((d:any) => {
                                                document.getElementById('row-result').innerHTML += `<h4 class="m-t-20 text-fiorentini"><b class="text-fiorentini">${d[0]}</b>: ${d[1]}</h4>`;
                                            });

                                            window.scrollTo(0, document.body.scrollHeight);
                                        }

                                    } else {
                                        document.querySelector('.result-message__success').classList.remove('visible');
                                        document.querySelector('.result-message__error').classList.add('visible');
                                    }
                                },
                                (error: any) => {
                                    this.contactFormCta.disabled = false;
                                    this.contactFormCta.classList.remove('loading');
                                    alert('Errore di connessione. Si prega di riprovare.');
                                    console.log(error);
                                });
                            });
                        });
            }

        });

    }

    public static performAsyncRequest(
        url: string,
        data: any,
        callback: Function,
        fallback?: Function
    ) {

        fetch(url, {
            headers: FetchHeaders.prepare(),
            method: 'post',
            credentials: 'same-origin',
            body: JSON.stringify(data)
        })
        .then(response => {
            if ( response.ok ) {
                response.json().then(data => {
                    if (data.success) {
                        callback(data);
                    } else {
                        fallback(data);
                    }
                });
            } else {
                fallback(response);
            }
        })
        .catch(error => {
            fallback(error);
        });
    }
}